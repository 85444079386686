<template>
  <!-- My 포인트 -->
  <coach-my-page-content-layout>
    UserQuestions
  </coach-my-page-content-layout>
</template>

<script>
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout';

export default {
  name: 'UserQuestions',
  components: { CoachMyPageContentLayout },
};
</script>
